import React from 'react';

import { Categories } from '../../components/views/settings/categories/categories'
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <Categories Action={0} />
        </LayoutLoggedIn>
    );
}